import React from "react";
import { Route, Routes } from "react-router-dom";
import loadable from "../util/loadable";

const Home_channel_2 = loadable(() => import("../page/Home_channel_2/Home_channel_2"));
const Home_channel_3 = loadable(() => import("../page/Home_channel_3/Home_channel_3"));
const Deposit = loadable(() => import("../page/Deposit/Deposit"));
const Fill_in_info = loadable(() => import("../page/Fill_in_info/Fill_in_info"));
const Payment_err = loadable(() => import("../page/Payment_err/Payment_err"));
const Recharge_payment_success = loadable(() => import("../page/recharge_payment_success/recharge_payment_success"));
const Coupon_list = loadable(() => import("../page/Coupon_list/Coupon_list"));
const Mobile_order = loadable(() => import("../page/Mobile_order/Mobile_order"));
const Serve_center = loadable(() => import("../page/Serve_center/Serve_center"));
const Login = loadable(() => import("../page/Login/Login"));
const Secret_agreement = loadable(() => import("../page/Secret_agreement/Secret_agreement"));
const User_agreement = loadable(() => import("../page/User_agreement/User_agreement"));
const Refund = loadable(() => import("../page/Refund/Refund"));
const Active_rule = loadable(() => import("../page/Active_rule/Active_rule"));
const Yqs_1 = loadable(() => import("../animation/page/Yqs_1/Yqs_1"));
const Red_1 = loadable(() => import("../animation/page/Red_1/Red_1"));
const Alipay_success = loadable(() => import("../page/Alipay_success/index"));
const Secret_agreement_5g = loadable(() => import("../page/Secret_agreement_5g/Secret_agreement"));
const User_agreement_5g = loadable(() => import("../page/User_agreement_5g/User_agreement"));
const Lucky_draw_5g = loadable(() => import("../page/Lucky_draw_5g/index"));
const ActivePink = loadable(() => import("../page/ActivePink/index"));
const Home_channel_4 = loadable(() => import("../page/Home_channel_4/index"));
const PayResult = loadable(() => import("../page/PayResult/index"));
const Home_channel_5 = loadable(() => import("../page/Home_channel_5/index"));
const Home_channel_6 = loadable(() => import("../page/Home_channel_6/index"));
const Home_channel_7 = loadable(() => import("../page/Home_channel_7/index"));
const Home_channel_8 = loadable(() => import("../page/Home_channel_8/index"));
const Home_channel_9 = loadable(() => import("../page/Home_channel_9/index"));
const Home_channel_10 = loadable(() => import("../page/Home_channel_10/index"));
const Home_channel_10_1 = loadable(() => import("../page/Home_channel_10_1/index"));
const Home_channel_11 = loadable(() => import("../page/Home_channel_11/index"));
const Home_channel_10_2 = loadable(() => import("../page/Home_channel_10_2/index"));
const Home_channel_12 = loadable(() => import("../page/Home_channel_12/index"));

let Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home_channel_8 />}></Route>
      <Route path="/home_channel_3" element={<Home_channel_3 />}></Route>
      <Route path="/home_channel_2" element={<Home_channel_2 />}></Route>
      <Route path="/active_rule" element={<Active_rule />}></Route>
      <Route path="/fill_in_info" element={<Fill_in_info />}></Route>
      <Route path="/yhq_list" element={<Coupon_list />}></Route>
      <Route path="/yhq_payment_err" element={<Payment_err />}></Route>
      <Route path="/recharge_payment_success" element={<Recharge_payment_success />}></Route>
      <Route path="/deposit" element={<Deposit />}></Route>
      <Route path="/mobile_order" element={<Mobile_order />}></Route>
      <Route path="/serve_center" element={<Serve_center />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/secret_agreement" element={<Secret_agreement />}></Route>
      <Route path="/user_agreement" element={<User_agreement />}></Route>
      <Route path="/refund" element={<Refund />}></Route>
      <Route path="/yqs_1" element={<Yqs_1 />}></Route>
      <Route path="/red_1" element={<Red_1 />}></Route>
      <Route path="/alipay_success" element={<Alipay_success />}></Route>
      <Route path="/secret_agreement_5g" element={<Secret_agreement_5g />}></Route>
      <Route path="/user_agreement_5g" element={<User_agreement_5g />}></Route>
      <Route path="/lucky_draw_5g" element={<Lucky_draw_5g />}></Route>
      <Route path="/activePink" element={<ActivePink />}></Route>
      <Route path="/home_channel_4" element={<Home_channel_4 />}></Route>
      <Route path="/payResult" element={<PayResult />}></Route>
      <Route path="/home_channel_5" element={<Home_channel_5 />}></Route>
      <Route path="/home_channel_6" element={<Home_channel_6 />}></Route>
      <Route path="/home_channel_7" element={<Home_channel_7 />}></Route>
      <Route path="/home_channel_8" element={<Home_channel_8 />}></Route>
      <Route path="/home_channel_9" element={<Home_channel_9 />}></Route>
      <Route path="/home_channel_10" element={<Home_channel_10 />}></Route>
      <Route path="/home_channel_10_1" element={<Home_channel_10_1 />}></Route>
      <Route path="/Home_channel_11" element={<Home_channel_11 />}></Route>
      <Route path="/home_channel_10_2" element={<Home_channel_10_2 />}></Route>
      <Route path="/home_channel_12" element={<Home_channel_12 />}></Route>
    </Routes>
  );
};

export default Router;
