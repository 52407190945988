import { createStore } from "redux";
let data = {
  company: null,
  package: null,
  // message_status:false
  //token:'8b00514b-bce2-4638-a8f5-bafc9ab87672',
  // is_success_status:true
  // detainment_top_pop_status:false  //左上角挽留弹框是否显示
};

const Reducer = (state = data, action) => {
  state[action.type] = action.value;
  return state;
};
const store = createStore(Reducer);
export default store;

// import { createStore, applyMiddleware } from 'redux';
// import rootReducer from './reducers';
// import thunk from 'redux-thunk';
//
// export default createStore(
//     rootReducer,
//     applyMiddleware(thunk)
// );
