export let countdown = (number) => {
  //1800 0.12 1799.88
  let num = number;
  let mm = "";
  let ss = "";
  let ms = "";
  mm = parseInt(num / 60); //29.998
  ss = parseInt(num - mm * 60); //59.88
  ms = parseInt((num - mm * 60 - ss) * 60);
  return {
    mm: mm < 10 ? "0" + mm : mm,
    ss: ss < 10 ? "0" + ss : ss,
    ms: ms < 10 ? "0" + ms : ms,
  };
};
export let time = (number) => {
  let date = new Date(number * 1000);
  let YY = date.getFullYear();
  let MM = date.getMonth() + 1;
  let DD = date.getDate();
  return `有效日期至${YY}年${MM}月${DD}日`;
};

export let get_params = (string) => {
  let reslute = "";
  let obj = {};
  if (string.indexOf("?") >= 0) {
    reslute = string.substring(string.indexOf("?") + 1);
    if (reslute.indexOf("&") >= 0) {
      reslute = reslute.split("&");
      reslute.forEach((item) => {
        obj[item.split("=")[0]] = item.split("=")[1];
      });
    } else {
      obj[reslute.split("=")[0]] = reslute.split("=")[1];
    }
  } else {
    reslute = string;
    obj = {};
  }
  return obj;
};
