import React from "react";
import { HashRouter as Router } from "react-router-dom";

import Routes from "./Routes";

import "./App.css";
import { get_params } from "./globle";

function App() {

  let ua = navigator.userAgent.toLowerCase(); //判断是否是微信环境
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    localStorage.setItem("is_vchar", "true");
  } else {
    localStorage.setItem("is_vchar", "false");
  }
  let params = get_params(window.location.href);
  sessionStorage.setItem("params_id", params.package_id ? params.package_id : 1);
  // eslint-disable-next-line no-prototype-builtins
  if (params.hasOwnProperty("channelCode")) {
    sessionStorage.setItem("channel_code", params.channelCode);
  } else {
    sessionStorage.setItem("channel_code", "");
  }
  let secScript = document.createElement("script");
  secScript.setAttribute("type", "text/javascript");
  if (window.location.href.indexOf("https://") >= 0) {
    secScript.setAttribute("src", "https://jic.talkingdata.com/app/h5/v1?appid=27050B892FB545D5A5894D3CDD3686BF&vn=动感生活&vc=1.0.1");
  } else {
    secScript.setAttribute("src", "http://sdk.talkingdata.com/app/h5/v1?appid=27050B892FB545D5A5894D3CDD3686BF&vn=动感生活&vc=1.0.1");
  }
  document.body.insertBefore(secScript, document.body.lastChild);

  return (
    <Router>
      <Routes></Routes>
    </Router>
  );
}

export default App;
